import React from "react";
import {
  AutocompleteInput,
  DateField,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  useRefresh,
} from "react-admin";
// Local
import { useTimeoutSlot } from "../../lib";
import { BoolNumInput /*, BoolNumField */ } from "../../components";

export { default as FulfillmentIcon } from "@material-ui/icons/Shop";

const AUTO_REFRESH_DELAY =
  process.env.NODE_ENV === "development" ? 30000 : 90000;

const FulfillmentFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      label="Shop"
      source="clientShopId"
      reference="shops"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput alwaysOn source="orderNum" />
    <TextInput alwaysOn source="name" />
    <BoolNumInput alwaysOn source="printed" />
  </Filter>
);

const FulfillmentList = props => {
  const refreshList = useRefresh();
  useTimeoutSlot(() => {
    refreshList();
  }, AUTO_REFRESH_DELAY);
  return (
    <List
      {...props}
      filters={<FulfillmentFilter />}
      filterDefaultValues={{ printed: false }}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={null}
    >
      <Datagrid optimized rowClick="show">
        <ReferenceField
          label="Shop"
          source="clientShopId"
          reference="shops"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="createdAt"
          showTime
          label="Created"
          style={styles.nowrap}
        />
        <TextField source="orderNum" label="Order" />
        <TextField
          source="eCourierOrderNum"
          label={<span style={styles.nowrap}>e-Courier #</span>}
        />
        {/* <TextField source="eCourierOrderStatus" label="Status" /> */}
        {/* <BoolNumField source="printed" /> */}
        <TextField source="itemCount" label="Items" />
        <TextField source="itemSummary" label="Summary" />
        <TextField source="name" style={styles.nowrap} />
        <TextField source="email" />
      </Datagrid>
    </List>
  );
};

export default FulfillmentList;

const styles = {
  nowrap: {
    whiteSpace: "nowrap",
  },
};
