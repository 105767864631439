import { ErrorSentry } from "../components/errors/ErrorSentry";

const { REACT_APP_API_URL } = process.env;

/** LocalStorage key for auth info. */
const AUTH_KEY = "auth";

/** @type {{token:string,expiration:number,user:{id:number,email:string,roles:string[]}}} */
let auth = undefined;

/** @type {import("ra-core").AuthProvider} */
export const authProvider = {
  async login({ username, password }) {
    // console.log("login", username);
    const request = new Request(`${REACT_APP_API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const res = await fetch(request);
    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText);
    }
    auth = await res.json();
    ErrorSentry.setUser(auth);
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
  },
  async logout() {
    // console.log("logout");
    auth = undefined;
    localStorage.removeItem(AUTH_KEY);
    return Promise.resolve();
  },
  async checkError({ status }) {
    // console.log("checkError", status);
    return status === 401 || status === 403
      ? Promise.reject()
      : Promise.resolve();
  },
  async checkAuth() {
    // console.log("checkAuth");
    return auth ? Promise.resolve() : Promise.reject();
  },
  async getPermissions() {
    // console.log("getPermissions");
    return auth ? Promise.resolve(auth.user.roles) : Promise.reject();
  },
};

export function getAuthInfo() {
  return auth;
}

export function getAuthToken() {
  if (!auth) {
    loadAuth();
  }
  return auth?.token;
}

export function loadAuth() {
  const item = localStorage.getItem(AUTH_KEY);
  // console.log("loadAuth", item);
  if (item) {
    auth = JSON.parse(item);
    ErrorSentry.setUser(auth);
  } else {
    auth = undefined;
  }
}

loadAuth();
