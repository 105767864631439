import React from "react";
import PrintIcon from "@material-ui/icons/Print";
import {
  Button,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
// Local
import { Divider } from "../../components";
import { fetchJson } from "../../react-admin";

const { REACT_APP_API_URL } = process.env;

function _FulfillmentToolbar({ basePath, data, resource }) {
  const onClickPrint = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      e.stopPropagation();
      const endpointUrl = `${REACT_APP_API_URL}/fulfillments/${data.id}/print`;
      const {
        json: { autoPrintLabel, labelUrl },
      } = await fetchJson(endpointUrl);
      printLabel(autoPrintLabel, labelUrl, endpointUrl);
    },
    [data],
  );
  return (
    <TopToolbar>
      <Button label="Print" onClick={onClickPrint}>
        <PrintIcon />
      </Button>
    </TopToolbar>
  );
}
const FulfillmentToolbar = React.memo(_FulfillmentToolbar);

const FulfillmentShow = props => (
  <Show actions={<FulfillmentToolbar />} {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="Shop"
        source="clientShopId"
        reference="shops"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <TextField source="orderNum" />
      <TextField source="eCourierOrderNum" />
      <TextField source="eCourierOrderStatus" />
      <Divider />
      <TextField source="itemCount" />
      <TextField source="itemSummary" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="name" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="stateCode" />
      <TextField source="zip" />
      <TextField source="phone" />
      <TextField source="email" />
      <Divider />
      <DateField source="printedAt" showTime />
      <ReferenceField
        label="Printed By"
        source="printedBy"
        reference="users"
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default FulfillmentShow;

function getPrintBody(url) {
  return `<body onload="window.print()">
    <iframe style="position:fixed; top:0px; left:0px; bottom:0px; right:0px;
    width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;
    z-index:999999;"
    src="${url}">
  </body>`;
}

function printLabel(autoPrintLabel, url, endpointUrl) {
  if (!autoPrintLabel) {
    window.open(url, "_blank");
    printedLabel(endpointUrl);
    return;
  }
  var data = getPrintBody(url);
  var win = window.open("about:blank", "_blank");
  win.document.write(data);
  win.addEventListener("afterprint", async () => {
    // NOTE: There is no way to know if the user ACTUALLY printed.
    // CONSIDER: We could ask the user with `window.confirm()` or a modal.
    await printedLabel(endpointUrl);
    win.close();
  });
  // IMPORTANT: Close the document so body.onload runs!
  win.document.close();
}

function printedLabel(url) {
  return fetchJson(url, {
    method: "POST",
    body: JSON.stringify({ value: true }),
  });
}
