import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Admin, Resource } from "react-admin";
// Local
import { ErrorSentry } from "./components";
import { setAuthRequestToken } from "./lib";
import { authProvider, dataProvider, i18nProvider } from "./react-admin";
import * as serviceWorker from "./serviceWorker";
import serviceWorkerConfig from "./serviceWorkerConfig";
// Pages
import { AuthPages } from "./pages";
// Resources
import { resources } from "./resources";

function main() {
  loadAuthToken();
  render();
  registerServiceWorker();
}

function loadAuthToken() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (auth) {
    setAuthRequestToken(auth.token, auth.expiration);
  }
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
function registerServiceWorker() {
  serviceWorker.register(serviceWorkerConfig);
}

function render() {
  ReactDOM.render(
    <ErrorSentry>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={AuthPages}
        title="MailPak PRIME"
      >
        {permissions => [
          // permissions ? <Resource {...things} /> : null,
          ...resources.map(res => <Resource {...res} />),
        ]}
      </Admin>
    </ErrorSentry>,
    document.getElementById("root"),
  );
}

main();
