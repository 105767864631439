import React from "react";
import { BooleanField } from "react-admin";

function _BoolNumField({ record = {}, source, ...props }) {
  return (
    <BooleanField
      record={{
        ...record,
        [source]: record[source] ? true : false,
      }}
      source={source}
      {...props}
    />
  );
}

export const BoolNumField = React.memo(_BoolNumField);
