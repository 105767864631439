import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
// Local
import { getAuthToken } from "./authProvider";

/**
 *
 * @param {string} url
 * @param {import("ra-core").fetchUtils.Options} options
 */
export function fetchJson(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = getAuthToken();
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const baseProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  fetchJson,
);

// NOTE: Not sure why this is necessary...
// const delayedDataProvider = new Proxy(baseProvider, {
//   get: (target, name, self) =>
//     name === "then" // as we await for the dataProvider, JS calls then on it. We must trap that call or else the dataProvider will be called with the then method
//       ? self
//       : (resource, params) =>
//           new Promise((resolve) =>
//             setTimeout(
//               () => resolve(baseProvider[name](resource, params)),
//               500,
//             ),
//           ),
// });

export const dataProvider = baseProvider;
