import React from "react";
import StoreIcon from "@material-ui/icons/Store";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
// Local

export const ShopIcon = StoreIcon;

const ShopBulkActionButtons = props => (
  <BulkDeleteWithConfirmButton {...props} undoable={false} />
);

const ShopFilter = props => (
  <Filter {...props}>
    <TextInput alwaysOn source="name" />
    <TextInput alwaysOn source="id" />
  </Filter>
);

const ShopList = props => (
  <List
    {...props}
    filters={<ShopFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "name", order: "ASC" }}
    bulkActionButtons={<ShopBulkActionButtons />}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="name" label="Shop name" />
      <ReferenceField
        label="Type"
        source="shopTypeId"
        reference="shop_types"
        link=""
      >
        <TextField source="name" />
      </ReferenceField>
      <UrlField
        source="webUrl"
        label="Website url"
        target="_blank"
        rel="noopener noreferrer"
      />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export default ShopList;
