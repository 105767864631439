import { Clients } from "./clients";
import { Shops } from "./shops";
import { Fulfillments } from "./fulfillments";
// import { Preferences } from "./preferences";
import { Users } from "./users";
import { ShopTypes } from "./shop_types";
// import { ShopifyFulfillments } from "./shopify_fulfillments";

// TODO: Enable preferences when auto-print label feature is working.

export const resources = [
  //
  Fulfillments,
  // ShopifyFulfillments,
  Shops,
  Users,
  // Preferences,

  // Hidden:
  Clients,
  ShopTypes,
];
export default resources;
