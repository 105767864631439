import React from "react";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useLogin, useNotify, Notification } from "react-admin";
// Local
import { AlternateEmailIcon, Link, VisibilityIcon } from "../../components";
import { useInputValue } from "../../lib";
import { useStyles } from "./LoginPage.styles";

function _LoginPage({ query }) {
  const login = useLogin();
  const notify = useNotify();
  const [uiLoading, setUILoading] = React.useState(false);
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [email, onChangeEmail] = useInputValue();
  const [password, onChangePassword] = useInputValue();
  const [passwordInputType, setPasswordInputType] = React.useState("password");

  const isMobile = false; // isMobile();

  const onClickLogin = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      e.preventDefault();
      setUILoading(true);
      await login({ username: email, password }).catch(err => {
        const msg = "Invalid email or password.";
        notify(msg);
        setErrorMessage(msg);
        setUILoading(false);
      });
    },
    [email, password, login, notify],
  );

  let titleText = "Welcome";
  let subtitleText = (
    <>
      Enter your details below.
      <br />
      Any credentials that follow the validation rules will work in this demo.
    </>
  );
  if (query.reset === "true") {
    titleText = "Your password was reset!";
    subtitleText = "Sign in again and you're good to go.";
  }

  // function navigateAfterLogin() {
  //   if (query.navigateAfterLoginURL) {
  //     window.location.replace(query.navigateAfterLoginURL);
  //   } else {
  //     window.location.replace("/");
  //   }
  // }

  function onClickTogglePassword(e) {
    e.preventDefault();
    setPasswordInputType(current => {
      return current === "password" ? "text" : "password";
    });
  }

  // useOnMount(() => {
  //   if (hasAuthRequestToken()) {
  //     navigateAfterLogin();
  //   }
  // });

  return (
    <div className={classes.rootContainer}>
      <Typography variant="h5">{titleText}</Typography>
      <Notification />
      {errorMessage ? (
        <Typography variant="subtitle1" className={classes.error}>
          {errorMessage}&nbsp;
        </Typography>
      ) : (
        <Typography variant="subtitle1">{subtitleText}&nbsp;</Typography>
      )}
      <form className="form" noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          autoFocus={!isMobile}
          value={email}
          onChange={onChangeEmail}
          disabled={uiLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AlternateEmailIcon
                  style={{ color: isMobile ? "#0B79A2" : "white" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={passwordInputType}
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={onChangePassword}
          disabled={uiLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon
                  onClick={onClickTogglePassword}
                  style={{
                    cursor: "pointer",
                    color: isMobile
                      ? "rgba(255,255,255,0.38)"
                      : "rgba(0,0,0,0.38)",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            fullWidth={isMobile}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onClickLogin}
            disabled={uiLoading}
            size="large"
          >
            Sign In
          </Button>
        </div>
        <Grid container>
          <Grid item xs={12} className={classes.submitHelp}>
            {uiLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Link
                to={"/login?page=forgot-password"}
                style={{ color: isMobile ? undefined : "#000000" }}
              >
                Forgot password?
              </Link>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export const LoginPage = React.memo(_LoginPage);
