import React from "react";
import { Labeled, Show, SimpleShowLayout, TextField } from "react-admin";
// Local
import { BoolNumField } from "../../components";

const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="email" />
      <Labeled label="Email Confirmed">
        <BoolNumField source="emailConfirmed" />
      </Labeled>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
