import React from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
// Local
import { LoginPage } from "./LoginPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { ResetPasswordPage } from "./ResetPasswordPage";

const Pages = {
  "confirm-account": ResetPasswordPage,
  "forgot-password": ForgotPasswordPage,
  "reset-password": ResetPasswordPage,
};

/** Decides which `/login` page to show based on `?page=` query. */
function _AuthPages() {
  const route = useLocation();
  const query = React.useMemo(() => qs.parse(route.search), [route.search]);
  // console.log("Rendering", route.search, query);

  const CurrentAuthPage = Pages[query.page] || LoginPage;

  return (
    <div style={{ paddingLeft: "15%", paddingRight: "15%", paddingTop: 100 }}>
      <CurrentAuthPage query={query} />
    </div>
  );
}

export const AuthPages = React.memo(_AuthPages);
