import React from "react";
import { BooleanInput, TextInput } from "react-admin";

function _PreferenceInput({ record = {}, source, ...props }) {
  return record.type === "boolean" ? (
    <BooleanInput
      record={{
        ...record,
        [source]: record[source] ? true : false,
      }}
      source={source}
      {...props}
    />
  ) : (
    <TextInput record={record} source={source} {...props} />
  );
}

export const PreferenceInput = React.memo(_PreferenceInput);
