// Local
import UserList, { UserIcon } from "./UserList";
import UserShow from "./UserShow";

export const Users = {
  name: "users",
  list: UserList,
  show: UserShow,
  icon: UserIcon,
  options: {
    label: "Users",
  },
};
