import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-admin";

function _LinkButton({ children, ...rest }) {
  return (
    <Button component={Link} onClick={stopPropagation} {...rest}>
      {children}
    </Button>
  );
}
export const LinkButton = React.memo(_LinkButton);

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();
