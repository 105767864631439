import React from "react";
import { SaveButton, Toolbar } from "react-admin";

function _SaveOnlyToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}
export const SaveOnlyToolbar = React.memo(_SaveOnlyToolbar);
