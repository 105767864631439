// Local
import ShopEdit from "./ShopEdit";
import ShopList, { ShopIcon } from "./ShopList";
import ShopShow from "./ShopShow";

export const Shops = {
  name: "shops",
  edit: ShopEdit,
  list: ShopList,
  show: ShopShow,
  icon: ShopIcon,
  options: {
    label: "Shops",
  },
};
