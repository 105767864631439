import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
} from "react-admin";
// Local
import { BoolNumField } from "../../components";

export const UserIcon = PeopleIcon;

const UserBulkActionButtons = props => (
  <BulkDeleteWithConfirmButton {...props} undoable={false} />
);

const UserFilter = props => (
  <Filter {...props}>
    <TextInput alwaysOn source="email" />
    <TextInput alwaysOn source="id" />
  </Filter>
);

const UserList = props => (
  <List
    {...props}
    filters={<UserFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "email", order: "ASC" }}
    bulkActionButtons={<UserBulkActionButtons />}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="email" type="email" />
      <BoolNumField source="emailConfirmed" />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export default UserList;
