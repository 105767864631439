// Local
import FulfillmentList, { FulfillmentIcon } from "./FulfillmentList";
import FulfillmentShow from "./FulfillmentShow";

export const Fulfillments = {
  name: "fulfillments",
  list: FulfillmentList,
  show: FulfillmentShow,
  icon: FulfillmentIcon,
  options: {
    label: "Fulfillments",
  },
};
