import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  EditButton,
  Labeled,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
} from "react-admin";
// Local
import { LinkButton } from "../../components";
import { FulfillmentIcon } from "../fulfillments/FulfillmentList";

const { REACT_APP_API_URL } = process.env;

function _ShopShowToolbar({ basePath, data, resource }) {
  return (
    <TopToolbar>
      <LinkButton
        label="Fulfillments"
        to={{
          pathname: "/fulfillments",
          search: `?filter=${encodeURIComponent(
            JSON.stringify({
              clientShopId: data?.id,
              printed: false,
            }),
          )}`,
        }}
        style={{ marginRight: "auto" }}
      >
        <FulfillmentIcon />
      </LinkButton>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
}
const ShopShowToolbar = React.memo(_ShopShowToolbar);

const ShopShow = props => (
  <Show actions={<ShopShowToolbar />} {...props}>
    <TabbedShowLayout>
      <Tab label="Shop">
        <ReferenceField
          label="Shop Type"
          source="shopTypeId"
          reference="shop_types"
          link=""
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
      </Tab>
      <Tab label="e-Courier" path="ecourier">
        <TextField source="eCourierCustomerCode" label="Customer code" />
      </Tab>
      <Tab label="Pickup" path="pickup">
        <TextField source="pickupName" label="Name" />
        <TextField source="pickupAddress" label="Address" />
        <TextField source="pickupCity" label="City" />
        <TextField source="pickupState" label="State" />
        <TextField source="pickupZip" label="Zip" />
        <TextField source="pickupCountry" label="Country" />
        <TextField source="pickupPhone" label="Phone" />
        <TextField source="pickupTimeAdd" label="Time to schedule pickup" />
        <TextField source="pickupTimeZone" label="Timezone" />
      </Tab>
      <Tab label="Shopify" path="shopify">
        <TextField
          source="fulfillmentReadyStatus"
          label="Fulfillment status when ready to send order to e-Courier"
          emptyText="pending"
        />
        <TextField
          source="trackingCompany"
          label="Tracking company name used to identify MailPak in fulfillments"
          emptyText="MailPak"
        />
        <UrlField
          source="webUrl"
          label="Website url"
          target="_blank"
          rel="noopener noreferrer"
          emptyText="(none specified)"
        />
        <ShopifyWebhookUrls fullWidth style={{ marginBottom: 32 }} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default ShopShow;

const cell1Style = {
  backgroundColor: "ghostwhite",
  fontWeight: "bold",
};
const cell2Style = {
  borderLeft: "1px solid lightgrey",
};
const headerStyle = {
  marginBottom: 0,
};

function _ShopifyWebhookUrls({ record, style, ...rest }) {
  if (record.shopTypeId !== 1) {
    return null;
  }
  return (
    <Labeled
      label={<h2 style={headerStyle}>Shopify Webhooks</h2>}
      record={record}
      {...rest}
    >
      <div style={style}>
        <small>
          <em>
            To use Shopify, the following webhooks must be added to the Shopify{" "}
            <b>Shop Settings -&gt; Notifications -&gt; Webhooks</b> (bottom of
            the page).
          </em>
        </small>
        <br />
        <br />
        <small>
          <em>
            In the Shopify settings click <b>Create webhook</b>, choose the
            event shown and paste in the URL shown below. Remember to save your
            webhook signature here.
          </em>
        </small>
        <br />
        <br />
        <Box boxShadow={3}>
          <Table>
            <TableHead style={{ backgroundColor: "gainsboro" }}>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell style={cell2Style}>URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={cell1Style}>Fulfillment creation</TableCell>
                <TableCell
                  style={cell2Style}
                >{`${REACT_APP_API_URL}/shopify/shop/${record.id}/fulfillment/created`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={cell1Style}>Fulfillment update</TableCell>
                <TableCell
                  style={cell2Style}
                >{`${REACT_APP_API_URL}/shopify/shop/${record.id}/fulfillment/updated`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </div>
    </Labeled>
  );
}
export const ShopifyWebhookUrls = React.memo(_ShopifyWebhookUrls);
