/** @param {WebSocketClient} client */
function cleanup(client) {
  if (!client) {
    return;
  }
  const { ws } = client;
  if (ws && ws.readyState !== ws.CLOSED) {
    ws.close();
  }
  delete client.ws;
}

/**
 * WebSocket client that continually reconnects until disabled.
 */
export class WebSocketClient {
  /** True if the client should be connected or trying to connect. */
  enabled = false;
  /** True if logging messages should be printed out. */
  logging = false;
  /**
   * The current URL to stay enabled to.
   * @type {string}
   */
  url;
  /**
   * The internal WebSocket connection.
   * @type {WebSocket}
   */
  ws;

  /** @param {WebSocketClientOptions} options */
  constructor(options = {}) {
    Object.assign(this, options);
  }

  connect = async () => {
    this.log("WebSocket connecting.");
    this.enabled = false;
    cleanup(this);
    this.enabled = true;
    const url = await this.getUrl();
    this.ws = new WebSocket(url);
    this._handleSocketEvents();
    return url;
  };

  disconnect = async () => {
    this.log("WebSocket disconnecting.");
    this.enabled = false;
    cleanup(this);
  };
  /** Gets the URL to use when connecting. */
  getUrl = async () => Promise.resolve(this.url);
  /** Logs a message if `logging` is enabled. */
  log = (...args) => {
    if (!this.logging) {
      return;
    }
    console.log(...args);
  };
  /** @param {CloseEvent} e */
  onClose = e => {};
  /** @param {Event} e */
  onError = e => {};
  /** @param {MessageEvent} e */
  onMessage = e => {};
  /** @param {Event} e */
  onOpen = e => {};

  /**
   * Transmits data using the WebSocket connection.
   * @param {string | ArrayBufferLike | Blob | ArrayBufferView} data
   */
  send = data => this.ws.send(data);

  _handleSocketEvents() {
    const ws = this.ws;
    ws.addEventListener("open", e => {
      this.log("WebSocket open.");
      this.onOpen(e);
    });
    ws.addEventListener("close", e => {
      this.log("WebSocket closed.");
      this.onClose(e);
      if (!this.enabled) {
        return;
      }
      setTimeout(this.connect, 3000);
    });
    ws.addEventListener("error", e => {
      this.log("WebSocket error.", e);
      this.onError(e);
    });
    ws.addEventListener("message", e => {
      this.log("WebSocket message.", e.data, e);
      this.onMessage(e);
    });
  }
}

/**
 * @typedef {object} WebSocketClientOptions
 * @property {() => Promise<string>} [getUrl]
 * @property {boolean} [logging]
 * @property {(e:Event) => any} [onError]
 * @property {(e:MessageEvent) => any} [onMessage]
 * @property {(e:Event) => any} [onOpen]
 * @property {(e:CloseEvent) => any} [onClose]
 * @property {string} [url]
 */
