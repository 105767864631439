import React from "react";
import {
  Edit,
  FormTab,
  Labeled,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from "react-admin";
// Local
import { SaveOnlyToolbar } from "../../components";
import { ShopifyWebhookUrls } from "./ShopShow";

const ShopEdit = props => (
  <Edit undoable={false} {...props}>
    <TabbedForm redirect="list" toolbar={<SaveOnlyToolbar />}>
      <FormTab label="Shop">
        <ReferenceField
          label="Client"
          source="clientId"
          reference="clients"
          link=""
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Shop Type"
          source="shopTypeId"
          reference="shop_types"
          link=""
        >
          <TextField source="name" />
        </ReferenceField>
        <TextInput source="name" fullWidth validate={[required()]} />
        <TextInput source="webUrl" fullWidth label="Website url" />
      </FormTab>
      <FormTab label="e-Courier" path="ecourier">
        <TextInput source="eCourierCustomerCode" label="Customer code" />
      </FormTab>
      <FormTab label="Pickup" path="pickup">
        <TextInput source="pickupName" fullWidth label="Name" />
        <TextInput source="pickupAddress" fullWidth label="Address" />
        <TextInput source="pickupCity" fullWidth label="City" />
        <TextInput source="pickupState" fullWidth label="State" />
        <TextInput source="pickupZip" fullWidth label="Zip" />
        <TextInput source="pickupCountry" fullWidth label="Country" />
        <TextInput source="pickupPhone" fullWidth label="Phone" />
        <TextInput
          source="pickupTimeAdd"
          fullWidth
          label="Time to schedule pickup (e.g. 15 minutes)"
        />
        <TextInput source="pickupTimeZone" fullWidth label="Timezone" />
      </FormTab>
      <FormTab label="Shopify" path="shopify">
        <Labeled
          fullWidth
          label={
            <>
              <b>Fulfillment status(es)</b> when ready to send order to
              e-Courier. Separate multiple values with a comma.
              <br />
              <br />
              Use <em>none</em> to indicate no status.
            </>
          }
        >
          <TextInput source="fulfillmentReadyStatus" />
        </Labeled>
        <Labeled
          fullWidth
          label={
            <>
              <b>Tracking company name(s)</b> used to identify MailPak in
              fulfillments. Separate multiple values with a comma.
              <br />
              <br />
              Use <em>none</em> to indicate no tracking company.
            </>
          }
        >
          <TextInput source="trackingCompany" />
        </Labeled>
        <ShopifyWebhookUrls fullWidth style={{ marginBottom: 16 }} />
        <Labeled
          fullWidth
          label={
            <>
              The <b>webhook signature</b> is only seen in the Shopify settings
              after creating a webhook. Copy it and paste here. Save this shop
              to see the webhook URLs to enter into Shopify.
            </>
          }
        >
          <TextInput source="webhookSignature" fullWidth />
        </Labeled>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ShopEdit;
